<!--
  PACKAGE_NAME : src\pages\cc\emc\config\nreason.vue
  FILE_NAME : nreason
  AUTHOR : ycnam
  DATE : 2024-11-11
  DESCRIPTION : EMC 이석사유 설정
-->
<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid 
        :data-grid="dataGrid" 
        :ref="dataGrid.refName" 
        @init-new-row="onInitNewRow"
        @saving="onSaveData" 
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDate, isSuccess } from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
    },
    props: {},
    watch: {},
    data() {
      return {
        dataGrid: {
          callApi: 'CALL_EMC_API',
          refName: 'emcNreasonGrid',
          keyExpr: 'reasonId',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+reasonId',
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          //height:'calc(100vh - 470px)',    // 주석처리시 100%
          apiActionNm: {
            select: 'EMC_NREASON_LIST',
            merge: 'EMC_NREASON_MERGE',
            delete: 'EMC_NREASON_DELETE',
          },
          customEvent: {
            initNewRow: true,
            reorder: false,
            saving: true,            
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: false,
            update: true,
            delete: true,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          dragging: {
            sortColumn: 'reasonId',
            allowReordering: false,
            dropFeedbackMode: 'push',
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling:{  // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true,
            allowDeleting: false,
            allowAdding: true,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이석코드 아이디',
              i18n: 'EMC.WORD.NREASONID',
              dataField: 'reasonId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              validationRules: [
                {
                  type: 'required',
                  message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: '이석코드',
              i18n: 'EMC.WORD.NREASONCODE',
              dataField: 'reasonCode',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              validationRules: [
                {
                  type: 'required',
                  message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: '이석 텍스트',
              i18n: 'EMC.WORD.NREASONTEXT',
              dataField: 'reasonText',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              validationRules: [
                {
                  type: 'required',
                  message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: '설명',
              i18n: 'EMC.WORD.DESCRIPTION',
              dataField: 'description',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '등록자',
              i18n: 'COMPONENTS.REGISTRANT',
              dataField: 'regId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              allowEditing: false,
              fixedPosition: 'center', // left or right
            },
            {
              caption: '수정자',
              i18n: 'COMPONENTS.MODIFIER',
              dataField: 'editId',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '등록시간',
              i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
              dataField: 'regDate',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              // calculateCellValue: this.formatRegDt,
            },
            {
              caption: '수정시간',
              i18n: 'COMPONENTS.MODIFY_DATE_TIME',
              dataField: 'editDate',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              // calculateCellValue: this.formatRegDt,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 그리드 행 추가시 초기 설정 */
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
        e.data.regId = this.$store.getters.getLoginId;
      },
      /** @description: 운영스케줄 리스트 가져오기 */
      async selectNreasonList() {
        let payload = {
          actionname: 'EMC_NREASON_LIST',
          data: { sort: '+reasonId' },
          useErrorPopup: true,
        };

        const res = await this.CALL_EMC_API(payload);
        let rstData = [];
        console.log(res);
        if (isSuccess(res)) {
          rstData = res.data.data;
        }
        return rstData;
      },
      /** @description: 데이터 저장 메서드 */
      async onSaveData(e) {
        e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

        // 변경된 값이 없으면 메시지 출력
        if (e?.changes.length === 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_CHANGED', {defaultValue: '변경된 데이터가 없습니다.'}));
        }

        let data = [];

        e.changes.forEach(d => {
          let keyExpr = this.dataGrid.keyExpr; // ex) id
          let dataKey = d.key; // ex) 1
          let dataMap = d.data; // ex) { value: 100, name: 'test' }
          d.data.memberId = this.$store.getters.getLoginId;

          //표시여부
          if (d.data?.viewFl) {
            d.data.viewFl = d.data.viewFl === 'Y' ? 'Y' : 'N';
          }
          // 수정/신규/병합 타입 확인 후 데이터 맵에 등록자/수정자 정보 추가
          if (d.type === 'update') {

            // 병합은 ROW 전체 데이터로 dataMap 설정
            e.component
              .byKey(dataKey)
              .then(data => {
                dataMap = Object.assign(data, d.data); // Object.assign() 으로 기존 데이터에 변경된 데이터를 덮어씌움
              })
              .catch(error => {
                this.$log.error(error);
              });
          }

          data.push(dataMap); // ex) [{ id: 1, value: 100, name: 'test' }]
        });

        const payload = {
          actionname: 'EMC_NREASON_MERGE',
          data: data,
        }
        
        const res = await this.CALL_EMC_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          e.component.cancelEditData();
          this.refreshData(); 
        }
      },     
      /** @description : 그리드 refesh 메서드 */
      refreshData() {
        this.$refs[this.dataGrid.refName].refreshData();
      },       
      /** @description: 등록일시 데이터 출력 */
      formatRegDt(rowData) {
        if (rowData?.regDt) {
          return formatDate(rowData?.regDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description: 수정일시 데이터 출력 */
      formatEditDt(rowData) {
        if (rowData?.editDt) {
          return formatDate(rowData?.editDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM.DD HH:mm:ss');
        }
      },
    },
    async created() {},
  };
</script>
